import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import dayjs from "dayjs";
import ReactDOM from 'react-dom';
import "./styles/css/index.css";
import { Provider } from "react-redux";
import { App } from './App';
import { icons } from './assets/icons'
import reportWebVitals from './reportWebVitals';
import configureStore from "./store/configureStore";
import { Slide, ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import '@coreui/coreui/dist/css/coreui.min.css'
require("dotenv").config();
require("dayjs/locale/id");

dayjs.locale("id");
const store = configureStore();
React.icons = icons

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        newestOnTop
        draggable
        pauseOnHover
        transition={Slide}
      />
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
