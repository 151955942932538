const LOGIN_CHECK_SUCCESS = 'LOGIN_CHECK_SUCCESS';


export default function reducer(state = {role : 'superuser' }, action) {
  switch (action.type) {
    // do reducer stuff
    case LOGIN_CHECK_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function setRole(role) {
  return { type: LOGIN_CHECK_SUCCESS, payload: { role } };
}

export function setRoleAsync(role) {
  return (dispatch) => {
    if (role) {
      dispatch(setRole(role));
      return;
    }
      dispatch(setRole(role));
  };
}
