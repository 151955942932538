import Cookies from "js-cookie";
import JwtDecode from "jwt-decode";
var CryptoJS = require("crypto-js");

export const isAuthenticated = () => {
  Cookies.getJSON("api_token");
};

export const PostMessageRN = (data) => {
  if (window.hasOwnProperty("ReactNativeWebView")) {
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }
};

export const saveToken = (token, refreshToken) => {
  var tokenEncrypted = CryptoJS.AES.encrypt(token, process.env.REACT_APP_KEY_AES).toString();
  var refreshTokenEncrypted = CryptoJS.AES.encrypt(refreshToken, process.env.REACT_APP_KEY_AES).toString();

  if (!token || !refreshToken) {
    return;
  }
  Cookies.set(process.env.REACT_APP_KEY_TOKEN, tokenEncrypted);
  Cookies.set(process.env.REACT_APP_KEY_REFRESH_TOKEN, refreshTokenEncrypted);
};

export const deleteAllToken = () => {
  Cookies.remove(process.env.REACT_APP_KEY_TOKEN);
  Cookies.remove(process.env.REACT_APP_KEY_REFRESH_TOKEN);
  Cookies.remove(process.env.REACT_APP_KEY_TOKEN_ONBOARDING);
  localStorage.removeItem("users")
};

export const getToken = (typeToken) => {
  const token = Cookies.get(typeToken);
  if (!token) {
    return false;
  }
  const bytes  = CryptoJS.AES.decrypt(token, process.env.REACT_APP_KEY_AES);
  const tokenjwt = bytes.toString(CryptoJS.enc.Utf8);
  return tokenjwt;
};

export const decodeAuth = () => {
  const ac = getToken(process.env.REACT_APP_KEY_TOKEN);
  if (ac) {
    return JwtDecode(ac);
  }
  return false;
};
