import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import sidebarReducer from "../services/reducers/sidebar.reducer";
import roleReducer from "../services/reducers/role.reducer";
import authReducer from "../services/reducers/auth.reducer";
import loadingReducer from "../services/reducers/loading.reducer";

const makeRootReducer = (history) => {
  return combineReducers({
    router: connectRouter(history),
    RxSidebar: sidebarReducer,
    RxRole : roleReducer,
    RxName : authReducer,
    global: loadingReducer,
  });
};

export default makeRootReducer;
