const SET_SIDEBAR_RESPONSIVE = 'SET-RESPONSIVE';


export default function reducer(state = { sidebarShow: true }, action) {
  
  switch (action.type) {
    // do reducer stuff
    case SET_SIDEBAR_RESPONSIVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function setSidebar(sidebarShow) {
  
  return { type: SET_SIDEBAR_RESPONSIVE, payload: { sidebarShow } };
}

export function setSideBarAsync(sidebarShow) {
  return (dispatch) => {
    if (sidebarShow) {
      dispatch(setSidebar(sidebarShow));
      return;
    }

      dispatch(setSidebar(sidebarShow));
  };
}
