import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { getToken } from "./utils/cookie";
import Loading from "./components/Loading";

const EditSO = lazy(() => import("pages/Report/DataSO/EditSO"));

const TambahSalesSO = lazy(() => import("./pages/KepalaUnit/ManagementData/ManagementSales/ModalSalesSO/TambahSales"));
const EditSalesSO = lazy(() => import("./pages/KepalaUnit/ManagementData/ManagementSales/ModalSalesSO/EditSales"));


const TheLayout = lazy(() => import("./containers/TheLayout"));
const Login = lazy(() => import("./pages/Login/Login"));
const LupaPass = lazy(() => import("./pages/LupaPassword/LupaPassword"));
const PassBaru = lazy(() => import("./pages/PasswordBaru/PasswordBaru"));
const Dashboard = lazy(() => import("./pages/KepalaUnit/Dashboard/Dashboard"));
const PengajuanEvent = lazy(() =>
  import("./pages/KepalaUnit/PengajuanEvent/PengajuanEvent"),
);
const TambahEvent = lazy(() =>
  import("./pages/KepalaUnit/PengajuanEvent/TambahPengEvent"),
);
const DetailEvent = lazy(() =>
  import("./pages/KepalaUnit/PengajuanEvent/DetailPengajuan"),
);
const MasterCompany = lazy(() =>
  import("./pages/KepalaUnit/MasterCompany/MasterCompany"),
);
const MasterBranch = lazy(() =>
  import("./pages/KepalaUnit/MasterBranch/MasterBranch"),
);
const RealisasiEvent = lazy(() =>
  import("./pages/KepalaUnit/RealisasiEvent/RealisasiEvent"),
);
const DetailRealisasi = lazy(() =>
  import("./pages/KepalaUnit/RealisasiEvent/DetailEvent"),
);
const InputRealisasi = lazy(() =>
  import("./pages/KepalaUnit/RealisasiEvent/InputRealisasiEv"),
);
const PengembalianRealisasi = lazy(() =>
  import("./pages/KepalaUnit/PengembalianRealisasi/PengembalianRealisasi"),
);
const RiwayatEvent = lazy(() =>
  import("./pages/KepalaUnit/RiwayatEvent/RiwayatEvent"),
);
const DetailRiwayatEvent = lazy(() =>
  import("./pages/KepalaUnit/RiwayatEvent/DetailRiwayatEv"),
);
const AktivitasHarian = lazy(() =>
  import("./pages/KepalaUnit/AktivitasHarian/AktivitasHarian"),
);
const TambahAktivitas = lazy(() =>
  import("./pages/KepalaUnit/AktivitasHarian/TambahAktivitasHarian"),
);
const DetailAktivitas = lazy(() =>
  import("./pages/KepalaUnit/AktivitasHarian/DetailAktivitas"),
);
const ManagementSales = lazy(() =>
  import("./pages/KepalaUnit/ManagementData/ManagementSales/ManagementSales"),
);
const Logout = lazy(() => import("./pages/Login/Logout"));
const TambahSales = lazy(() =>
  import("./pages/KepalaUnit/ManagementData/ManagementSales/TambahSales"),
);
const EditSales = lazy(() =>
  import("./pages/KepalaUnit/ManagementData/ManagementSales/EditSales"),
);
const ManagementMitra = lazy(() =>
  import("./pages/KepalaUnit/ManagementData/ManagementMitra/ManagementMitra"),
);
const TambahMitra = lazy(() =>
  import("./pages/KepalaUnit/ManagementData/ManagementMitra/TambahMitra"),
);
const EditMitra = lazy(() =>
  import("./pages/KepalaUnit/ManagementData/ManagementMitra/EditMitra"),
);

const ApprovalEvent = lazy(() =>
  import("./pages/BM/ApprovalEvent/ApprovalEvent"),
);
const DetailPengajuan = lazy(() =>
  import("./pages/BM/ApprovalEvent/DetailPengajuan"),
);
const DescPengajuan = lazy(() =>
  import("./pages/BM/ApprovalEvent/DescPengajuan"),
);
const DetailApproval = lazy(() =>
  import("./pages/BM/ApprovalEvent/DetailApproval"),
);
const DescApproval = lazy(() =>
  import("./pages/BM/ApprovalEvent/DescApproval"),
);
const DaftarEvent = lazy(() => import("./pages/BM/DaftarEvent/DaftarEvent"));
const DaftarEventBranch = lazy(() =>
  import("./pages/BM/DaftarEvent/DaftarEventBranch"),
);
const DetailDaftarEvent = lazy(() =>
  import("./pages/BM/DaftarEvent/DetailDaftar"),
);

const UploadDesign = lazy(() => import("./pages/BD/FileDesign/FileDesign"));
// const DetailFileDesign = lazy(() =>import("./pages/BD/FileDesign/DetailFileDesign"));

const TransferEvent = lazy(() =>
  import("./pages/Finance/TransferEvent/TransferEvent"),
);
const DetailTransfer = lazy(() =>
  import("./pages/Finance/TransferEvent/DetailTransfer"),
);
const History = lazy(() => import("./pages/Finance/TransferEvent/History"));
const TopUp = lazy(() => import("./pages/Finance/TransferEvent/TopUp"));
const ApprovalPengembalian = lazy(() =>
  import(
    "./pages/Finance/RealisasiEvent/ApprovalPengembalian/ApprovalPengembalian"
  ),
);
const ApprovalRealisasi = lazy(() =>
  import("./pages/Finance/RealisasiEvent/ApprovalRealisasi/ApprovalRealisasi"),
);

const LaporanInsentif = lazy(() =>
  import("./pages/Finance/LaporanInsentif/LaporanInsentif"),
);
const LapInsen = lazy(() =>
  import("./pages/Finance/LaporanInsentif/LaporanInsentif"),
);
const DetailPengembalian = lazy(() =>
  import(
    "./pages/Finance/RealisasiEvent/ApprovalPengembalian/DetailPengembalian"
  ),
);
const DetailAproRealisasi = lazy(() =>
  import("./pages/Finance/RealisasiEvent/ApprovalRealisasi/DetailAproReali"),
);
const DetailEventPengajuan = lazy(() =>
  import("./pages/KepalaUnit/PengajuanEvent/DetailEvent"),
);

const DaftarEventFinance = lazy(() =>
  import("./pages/Finance/DaftarEvent/DaftarEvent"),
);
const DaftarEventBranchFinance = lazy(() =>
  import("./pages/Finance/DaftarEvent/DaftarEventBranch"),
);
const DetailDaftarEventFinance = lazy(() =>
  import("./pages/Finance/DaftarEvent/DetailDaftar"),
);

const ManajemenFollowup = lazy(() =>
  import("./pages/Telemarketing/ManajemenFollowup/ManajemenFollowup"),
);
const TambahManajemen = lazy(() =>
  import("./pages/Telemarketing/ManajemenFollowup/TambahManajemenFoll"),
);
const BorrowerClosing = lazy(() =>
  import("./pages/Telemarketing/BorrowerClosing/BorrowerClosing"),
);
const DetailBorrower = lazy(() =>
  import("./pages/Telemarketing/BorrowerClosing/DetailBorrower"),
);
const TeleManajemenFoll = lazy(() =>
  import("./pages/Telemarketing/TeleManajemenFollowup/TeleManajemenFoll"),
);

const DataSO = lazy(() => import("./pages/Report/DataSO/DataSO"));
const DataSA = lazy(() => import("./pages/Report/DataSA/DataSA"));
const DataLeads = lazy(() => import("./pages/Report/DataLeads/DataLeads"));
const DataEvent = lazy(() => import("./pages/Report/DataEvent/DataEvent"));
const BorrowerRegister = lazy(() => import("./pages/Report/BorrowerRegister/BorrowerRegister"));
const PencairanPinjaman = lazy(() => import("./pages/Report/PencairanPinjaman/PencairanPinjaman"));
const DataClosing = lazy(() =>
  import("./pages/Report/DataClosing/DataClosing"),
);

const CreateUser = lazy(() => import("./pages/User/CreateUser"));
const IndexUser = lazy(() => import("./pages/User/IndexUser"));
const EditUser = lazy(() => import("./pages/User/EditUser"));

const ChangePassword = lazy(() =>
  import("./pages/ChangePassword/ChangePassword"),
);
const EventWinBack = lazy(() =>
  import("./pages/Report/VoucherWinback/VoucherWinback"),
);

const reportWinback = lazy(() =>
  import("./pages/Report/Winback/Winback"),
);

const EditSA = lazy(() =>
  import("./pages/Report/DataSA/EditSales"),
);


// Onboarding

const LoginOnboarding = lazy(() => import("./pages_onboarding/Login/Login"));

const DashboardOnboarding = lazy(() =>
  import("./pages_onboarding/Administrator/Dashboard/Dashboard"),
);

const Company = lazy(() =>
  import("./pages_onboarding/Administrator/Master_mitra/Company/Company"),
);
const TambahCompany = lazy(() =>
  import("./pages_onboarding/Administrator/Master_mitra/Company/TambahCompany"),
);
const EditCompany = lazy(() =>
  import("./pages_onboarding/Administrator/Master_mitra/Company/EditCompany"),
);
const Branch = lazy(() =>
  import("./pages_onboarding/Administrator/Master_mitra/Branch/Branch"),
);
const TambahBranch = lazy(() =>
  import("./pages_onboarding/Administrator/Master_mitra/Branch/TambahBranch"),
);
const EditBranch = lazy(() =>
  import("./pages_onboarding/Administrator/Master_mitra/Branch/EditBranch"),
);
const UserAdmin = lazy(() =>
  import("./pages_onboarding/Administrator/Master_mitra/User_admin/User"),
);
const TambahUser = lazy(() =>
  import("./pages_onboarding/Administrator/Master_mitra/User_admin/TambahUser"),
);
const EditAdminUser = lazy(() =>
  import("./pages_onboarding/Administrator/Master_mitra/User_admin/EditUser"),
);
const Referensi = lazy(() =>
  import("./pages_onboarding/Administrator/Master_mitra/Referensi/Referensi"),
);
const TambahReferensi = lazy(() =>
  import(
    "./pages_onboarding/Administrator/Master_mitra/Referensi/TambahReferensi"
  ),
);
const EditReferensi = lazy(() =>
  import(
    "./pages_onboarding/Administrator/Master_mitra/Referensi/EditReferensi"
  ),
);
const Legal = lazy(() =>
  import("./pages_onboarding/Administrator/Master_jf/Legal/Legal"),
);
const TambahLegal = lazy(() =>
  import("./pages_onboarding/Administrator/Master_jf/Legal/TambahLegal"),
);
const EditLegal = lazy(() =>
  import("./pages_onboarding/Administrator/Master_jf/Legal/EditLegal"),
);
const Plafon = lazy(() =>
  import("./pages_onboarding/Administrator/Master_jf/Plafon/Plafon"),
);
const TambahPlafon = lazy(() =>
  import("./pages_onboarding/Administrator/Master_jf/Plafon/TambahPlafon"),
);
const EditPlafon = lazy(() =>
  import("./pages_onboarding/Administrator/Master_jf/Plafon/EditPlafon"),
);
const PlafonHistory = lazy(() =>
  import(
    "./pages_onboarding/Administrator/Master_jf/Plafon_history/PlafonHistory"
  ),
);
const TransaksiHarian = lazy(() =>
  import(
    "./pages_onboarding/Administrator/Transaksi/Transaksi_harian/Transaksi_harian"
  ),
);
const LogRekomendasi = lazy(() =>
  import(
    "./pages_onboarding/Administrator/Transaksi/Log_rekomendasi/Log_rekomendasi"
  ),
);
const CashBack = lazy(() =>
  import("./pages_onboarding/Administrator/Transaksi/Cash_back/Cash_back"),
);
const KwintansibackDate = lazy(() =>
  import(
    "./pages_onboarding/Administrator/Transaksi/Kwintansi_backdate/kwintansi_backdate"
  ),
);
const Enrollment = lazy(() =>
  import(
    "./pages_onboarding/Administrator/Join_Financing/Enrollment/Enrollment"
  ),
);
const ListPengajuan = lazy(() =>
  import(
    "./pages_onboarding/Administrator/Join_Financing/List_pengajuan/List_pengajuan"
  ),
);
const Approval = lazy(() =>
  import("./pages_onboarding/Administrator/Join_Financing/Approval/Approval"),
);
const ListPencairan = lazy(() =>
  import(
    "./pages_onboarding/Administrator/Join_Financing/List_pencairan/List_pencairan"
  ),
);
const Pelunasan = lazy(() =>
  import("./pages_onboarding/Administrator/Join_Financing/Pelunasan/Pelunasan"),
);
const ListPelunasan = lazy(() =>
  import(
    "./pages_onboarding/Administrator/Join_Financing/List_pelunasan/List_pelunasan"
  ),
);
const DataBorrower = lazy(() =>
  import(
    "./pages_onboarding/Administrator/Laporan/Data_borrower/Data_borrower"
  ),
);
const DataNonOcr = lazy(() =>
  import("./pages_onboarding/Administrator/Laporan/Data_non_ocr/Data_non_ocr"),
);
const Jflunas = lazy(() =>
  import("./pages_onboarding/Administrator/Laporan/jf_lunas/Jf_lunas"),
);
const JfAll = lazy(() =>
  import("./pages_onboarding/Administrator/Laporan/Jf_all/Jf_all"),
);
const JfAktif = lazy(() =>
  import("./pages_onboarding/Administrator/Laporan/Jf_aktif/Jf_aktif"),
);
const JfGrace = lazy(() =>
  import(
    "./pages_onboarding/Administrator/Laporan/Jf_grace_period/Jf_grace_period"
  ),
);
const JfOverDue = lazy(() =>
  import("./pages_onboarding/Administrator/Laporan/Jf_overdue/Jf_overdue"),
);
const JfBatal = lazy(() =>
  import("./pages_onboarding/Administrator/Laporan/Jf_batal/Jf_batal"),
);
const NasabahLunas = lazy(() =>
  import(
    "./pages_onboarding/Administrator/Data_nasabah/Nasabah_lunas/Nasabah_lunas"
  ),
);
const NasabahFuRo = lazy(() =>
  import(
    "./pages_onboarding/Administrator/Data_nasabah/FollowUp_Ro/FollowRo"
  ),
);

const NasabahBaru = lazy(() =>
  import("./pages_onboarding/Penaksir/Transaksi/Nasabah_baru/Nasabah_baru"),
);
const NasabahLama = lazy(() =>
  import("./pages_onboarding/Penaksir/Transaksi/nasabah_lama/Nasabah_lama"),
);

const MasterMenu = lazy(() => import('./pages_onboarding/superAdmin/MasterParameter/Menu/Menu'))
const MasterAksesOnboarding = lazy(() => import('./pages_onboarding/superAdmin/MasterParameter/Kelompok/Kelompok'))
const MitraOnboarding = lazy(() => import('./pages_onboarding/superAdmin/Mitra/Mitra'))
const TambahMitraOnboarding = lazy(() => import('./pages_onboarding/superAdmin/Mitra/TambahMitra'))
const EditMitraOnboarding = lazy(() => import('./pages_onboarding/superAdmin/Mitra/EditMitra'))


const SalesAgent = lazy(() =>
  import("./pages/ManagementSalesAgent/SalesAgent"),
);

const MasterCabang = lazy(() => import("./pages/MasterCabang/MasterCabang"));

const CekVoucher = lazy(() => import("./pages/CekVoucher/CekVoucher"));
const ReportCompany = lazy(() => import("./pages/Report/SalesTrack/SalesTrack"));
const ReportCabang = lazy(() => import("./pages/Report/Cabang/Cabang"));
const ReportSales = lazy(() => import("./pages/Report/Sales/Sales"));
const PencapaianSO = lazy(() => import("./pages/Report/PencapaianSales/SO"));
const PencapaianSA = lazy(() => import("./pages/Report/PencapaianSales/SA"));

const Winback = lazy(() => import("./pages/Winback/Winback"));
const MasterAkses = lazy(() => import("./pages/MasterAkses/MasterAkses"));
const TambahAkses = lazy(() => import("./pages/MasterAkses/TambahAkses"));

export const App = (props) => {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>
          <TheLayout>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/login" component={Login} />
            <Route exact path="/login-onboarding" component={LoginOnboarding} />
            <Route exact path="/lupa-password" component={LupaPass} />
            <Route exact path="/password-baru" component={PassBaru} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute
              exact
              path="/pengajuan-event"
              component={PengajuanEvent}
            />
            <PrivateRoute
              exact
              path="/tambah-pengajuan-event"
              component={TambahEvent}
            />
            <PrivateRoute
              exact
              path="/detail-pengajuan-event"
              component={DetailEventPengajuan}
            />
            <PrivateRoute
              exact
              path="/master-company"
              component={MasterCompany}
            />
            <PrivateRoute
              exact
              path="/master-branch"
              component={MasterBranch}
            />
            <PrivateRoute
              exact
              path="/realisasi-event"
              component={RealisasiEvent}
            />
            <PrivateRoute
              exact
              path="/detail-realisasi-event"
              component={DetailRealisasi}
            />
            <PrivateRoute
              exact
              path="/input-realisasi-event"
              component={InputRealisasi}
            />
            <PrivateRoute
              exact
              path="/pengembalian-realisasi"
              component={PengembalianRealisasi}
            />
            <PrivateRoute
              exact
              path="/riwayat-event"
              component={RiwayatEvent}
            />
            <PrivateRoute
              exact
              path="/detail-riwayat-event"
              component={DetailRiwayatEvent}
            />
            <PrivateRoute
              exact
              path="/aktivitas-harian"
              component={AktivitasHarian}
            />
            <PrivateRoute
              exact
              path="/detail-aktivitas-harian"
              component={DetailAktivitas}
            />
            <PrivateRoute
              exact
              path="/tambah-aktivitas-harian"
              component={TambahAktivitas}
            />
            <PrivateRoute
              exact
              path="/management-data/sales"
              component={ManagementSales}
            />
            <PrivateRoute
              exact
              path="/management-data/sales/tambah-sales"
              component={TambahSales}
            />
            <PrivateRoute
              exact
              path="/management-data/sales/edit-sales"
              component={EditSales}
            />
                        <PrivateRoute
              exact
              path="/management-data/sales/tambah-sales-so"
              component={TambahSalesSO}
            />
            <PrivateRoute
              exact
              path="/management-data/sales/edit-sales-so"
              component={EditSalesSO}
            />
            <PrivateRoute
              exact
              path="/management-data/mitra"
              component={ManagementMitra}
            />
            <PrivateRoute
              exact
              path="/management-data/mitra/tambah-mitra"
              component={TambahMitra}
            />
            <PrivateRoute
              exact
              path="/management-data/mitra/edit-mitra"
              component={EditMitra}
            />

            <PrivateRoute
              exact
              path="/approval-event"
              component={ApprovalEvent}
            />
            <PrivateRoute
              exact
              path="/detail-pengajuan-apr-event"
              component={DetailPengajuan}
            />
            <PrivateRoute
              exact
              path="/detail-pengajuan-apr-event-id"
              component={DescPengajuan}
            />
            <PrivateRoute
              exact
              path="/detail-approval-event"
              component={DetailApproval}
            />
            <PrivateRoute
              exact
              path="/detail-approval-event-id"
              component={DescApproval}
            />
            <PrivateRoute exact path="/daftar-event" component={DaftarEvent} />
            <PrivateRoute
              exact
              path="/daftar-event-branch"
              component={DaftarEventBranch}
            />
            <PrivateRoute
              exact
              path="/detail-daftar-event"
              component={DetailDaftarEvent}
            />

            <PrivateRoute
              exact
              path="/upload-file-design"
              component={UploadDesign}
            />
            {/* <PrivateRoute exact path="/detail-file-design" component={DetailFileDesign} /> */}

            <PrivateRoute
              exact
              path="/transfer-event"
              component={TransferEvent}
            />
            <PrivateRoute
              exact
              path="/detail-transfer-event"
              component={DetailTransfer}
            />
            <PrivateRoute
              exact
              path="/approval-pengembalian"
              component={ApprovalPengembalian}
            />
            <PrivateRoute
              exact
              path="/history-transfer-event"
              component={History}
            />
            <PrivateRoute
              exact
              path="/top-up-transfer-event"
              component={TopUp}
            />
            <PrivateRoute
              exact
              path="/approval-realisasi"
              component={ApprovalRealisasi}
            />
            <PrivateRoute
              exact
              path="/detail-approval-pengembalian"
              component={DetailPengembalian}
            />
            <PrivateRoute
              exact
              path="/detail-approval-realisasi"
              component={DetailAproRealisasi}
            />
            <PrivateRoute
              exact
              path="/laporan-insentif"
              component={LaporanInsentif}
            />
            <PrivateRoute
              exact
              path="/realisasi-event-fi"
              component={DaftarEventFinance}
            />
            <PrivateRoute
              exact
              path="/realisasi-event-branch-finance"
              component={DaftarEventBranchFinance}
            />
            <PrivateRoute
              exact
              path="/realisasi-daftar-event-finance"
              component={DetailDaftarEventFinance}
            />

            <PrivateRoute
              exact
              path="/manajemen-followup"
              component={ManajemenFollowup}
            />
            <PrivateRoute
              exact
              path="/tambah-manajemen-followup"
              component={TambahManajemen}
            />
            <PrivateRoute
              exact
              path="/borrower-closing"
              component={BorrowerClosing}
            />
            <PrivateRoute
              exact
              path="/detail-borrower-closing"
              component={DetailBorrower}
            />
            <PrivateRoute
              exact
              path="/tele-manajemen-followup"
              component={TeleManajemenFoll}
            />

            <PrivateRoute
              exact
              path="/report-data-sales-officer"
              component={DataSO}
            />
            <PrivateRoute
              exact
              path="/report-data-sales-agent"
              component={DataSA}
            />
            <PrivateRoute
              exact
              path="/report-data-event"
              component={DataEvent}
            />
            <PrivateRoute
              exact
              path="/report-data-leads"
              component={DataLeads}
            />
            <PrivateRoute
              exact
              path="/report-data-closing"
              component={DataClosing}
            />
            <PrivateRoute
              exact
              path="/report-borrower-register"
              component={BorrowerRegister}
            />
            <PrivateRoute
              exact
              path="/report-pencairan-pinjaman"
              component={PencairanPinjaman}
            />
            <PrivateRoute
              exact
              path="/change-password"
              component={ChangePassword}
            />

            <PrivateRoute exact path="/create-user" component={CreateUser} />
            <PrivateRoute exact path="/index-user" component={IndexUser} />
            <PrivateRoute exact path="/edit-user" component={EditUser} />

            <PrivateRoute
              exact
              path="/management-sales-agent"
              component={SalesAgent}
            />

            <PrivateRoute
              exact
              path="/master-cabang"
              component={MasterCabang}
            />

            <PrivateRoute exact path="/cek-voucher" component={CekVoucher} />
            <PrivateRoute
              exact
              path="/report-salestrack"
              component={ReportCompany}
            />
            <PrivateRoute
              exact
              path="/report-cabang"
              component={ReportCabang}
            />
            <PrivateRoute
              exact
              path="/pencapaian-so"
              component={PencapaianSO}
            />
            <PrivateRoute
              exact
              path="/pencapaian-sa"
              component={PencapaianSA}
            />
            <PrivateRoute exact path="/report-sales" component={ReportSales} />
            <PrivateRoute
              exact
              path="/report-voucher-winback"
              component={EventWinBack}
            />
             <PrivateRoute
              exact
              path="/report-winback"
              component={reportWinback}
            />

            <PrivateRoute exact path="/winback" component={Winback} />
            <PrivateRoute exact path="/master-akses" component={MasterAkses} />
            <PrivateRoute exact path="/create-akses" component={TambahAkses} />
            <PrivateRoute
              exact
              path="/report-data-sales-officer/edit"
              component={EditSO}
            />
                        <PrivateRoute
              exact
              path="/report-data-sales-agent/edit"
              component={EditSA}
            />


            <PrivateRoute exact path="/logout" component={Logout} />

            {/* onboarding */}

            <PrivateRoute
              exact
              path="/home"
              component={DashboardOnboarding}
            />
            <PrivateRoute
              exact
              path="/master-mitra/company"
              component={Company}
            />
            <PrivateRoute
              exact
              path="/master-mitra/tambah-company"
              component={TambahCompany}
            />
            <PrivateRoute
              exact
              path="/master-mitra/edit-company"
              component={EditCompany}
            />
            <PrivateRoute
              exact
              path="/master-mitra/branch"
              component={Branch}
            />
            <PrivateRoute
              exact
              path="/master-mitra/tambah-branch"
              component={TambahBranch}
            />
            <PrivateRoute
              exact
              path="/master-mitra/edit-branch"
              component={EditBranch}
            />
            <PrivateRoute
              exact
              path="/master-mitra/useradmin"
              component={UserAdmin}
            />
            <PrivateRoute
              exact
              path="/master-mitra/tambah-user"
              component={TambahUser}
            />
            <PrivateRoute
              exact
              path="/master-mitra/edit-user"
              component={EditAdminUser}
            />
            <PrivateRoute
              exact
              path="/master-mitra/referensi"
              component={Referensi}
            />
            <PrivateRoute
              exact
              path="/master-mitra/tambah-refensi"
              component={TambahReferensi}
            />
            <PrivateRoute
              exact
              path="/master-mitra/edit-refensi"
              component={EditReferensi}
            />
            <PrivateRoute
              exact
              path="/master-jf/legal"
              component={Legal}
            />
            <PrivateRoute
              exact
              path="/master-jf/tambah-legal"
              component={TambahLegal}
            />
            <PrivateRoute
              exact
              path="/master-jf/edit-legal"
              component={EditLegal}
            />
            <PrivateRoute
              exact
              path="/master-jf/plafon"
              component={Plafon}
            />
            <PrivateRoute
              exact
              path="/master-jf/tambah-plafon"
              component={TambahPlafon}
            />
            <PrivateRoute
              exact
              path="/master-jf/edit-plafon"
              component={EditPlafon}
            />
            <PrivateRoute
              exact
              path="/master-jf/plafonhis"
              component={PlafonHistory}
            />
            <PrivateRoute
              exact
              path="/transaksi/transaksiharian"
              component={TransaksiHarian}
            />
            <PrivateRoute
              exact
              path="/transaksi/logrekomendasi"
              component={LogRekomendasi}
            />
            <PrivateRoute
              exact
              path="/transaksi/cashback"
              component={CashBack}
            />
            <PrivateRoute
              exact
              path="/transaksi/kwitansibackdate"
              component={KwintansibackDate}
            />
            <PrivateRoute
              exact
              path="/jf/enrollment"
              component={Enrollment}
            />
            <PrivateRoute
              exact
              path="/jf/listpengajuan"
              component={ListPengajuan}
            />
            <PrivateRoute
              exact
              path="/jf/approval"
              component={Approval}
            />
            <PrivateRoute
              exact
              path="/jf/listpencairan"
              component={ListPencairan}
            />
            <PrivateRoute
              exact
              path="/jf/pelunasan"
              component={Pelunasan}
            />
            <PrivateRoute
              exact
              path="/jf/listpelunasan"
              component={ListPelunasan}
            />
            <PrivateRoute
              exact
              path="/laporan/borrower"
              component={DataBorrower}
            />
            <PrivateRoute
              exact
              path="/laporan/nonocr"
              component={DataNonOcr}
            />
            <PrivateRoute
              exact
              path="/laporan/pinjamanall"
              component={JfAll}
            />
            <PrivateRoute
              exact
              path="/laporan/pinjamanaktif"
              component={JfAktif}
            />
            <PrivateRoute
              exact
              path="/laporan/pinjamangp"
              component={JfGrace}
            />
            <PrivateRoute
              exact
              path="/laporan/pinjamanovd"
              component={JfOverDue}
            />
            <PrivateRoute
              exact
              path="/laporan/pinjamanlunas"
              component={Jflunas}
            />
            <PrivateRoute
              exact
              path="/laporan/pinjamanbatal"
              component={JfBatal}
            />
            <PrivateRoute
              exact
              path="/data-nasabah/nasabahlunas"
              component={NasabahLunas}
            />
            <PrivateRoute
              exact
              path="/transaksi/newpinjaman"
              component={NasabahBaru}
            />
            <PrivateRoute
              exact
              path="/transaksi/oldnasabah"
              component={NasabahLama}
            />
                <PrivateRoute
              exact
              path="/master-parameter/menu"
              component={MasterMenu}
            />
                  <PrivateRoute
              exact
              path="/master-mitra/mitra"
              component={MitraOnboarding}
            />
                 <PrivateRoute
              exact
              path="/master-mitra/tambah-mitra"
              component={TambahMitraOnboarding}
            />
                  <PrivateRoute
              exact
              path="/master-mitra/edit-mitra"
              component={EditMitraOnboarding}
            />
            <PrivateRoute exact path="/master-parameter/kelompok" component={MasterAksesOnboarding} />
            <PrivateRoute
              exact
              path="/data-nasabah/nasabahlunasfu"
              component={NasabahFuRo}
            />
          </TheLayout>
        </Switch>
      </Suspense>
    </Router>
  );
};

const PrivateRoute = ({ component, isAuthenticated, ...rest }) => {
  const isValid =
    getToken(process.env.REACT_APP_KEY_TOKEN) ||
    getToken(process.env.REACT_APP_KEY_TOKEN_ONBOARDING)
      ? true
      : false;
  return (
    <Route
      {...rest}
      render={(props) =>
        isValid ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
